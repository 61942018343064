import request from '../request.js'

export function addClothingColor(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElementsIllustrate/admin/add',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
export function upClothingColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElementsIllustrate/admin/up/list',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}
export function delClothingColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElementsIllustrate/admin/del/list',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}
export function adminClothingColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElementsIllustrate/admin/get/list',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}